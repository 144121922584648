import request from '@/utils/request'

//-------------查询结算总帐---------

//获取结算列表
export function settlementList(data) {
  return request({
    url: '/MA_settlement/list',
    method: 'get',
    params: data
  })
}

//添加结算
export function addSettlement(data) {
  return request({
    url: '/MA_settlement/settle',
    method: 'get',
    params: data,
  })
}

//导出结算
export function exportSettlement(data){
  return request({
    url: '/MA_settlement/exportExcel',
    method: 'post',
    params: data,
    responseType: "blob",
  })
}

//导出所有
export function exportSettlementAll(data){
  return request({
    url: '/MA_settlement/exportlist',
    method: 'post',
    params: data,
    responseType: "blob",
  })
}

//结算详情
export function settlementDetail(data){
  return request({
    url: '/MA_settlement/listDetail',
    method: 'get',
    params: data
  })
}
//商户列表
export function unSettleOrderList(data){
  return request({
    url: '/MA_settlement/unSettleOrderList',
    method: 'post',
    params: data
  })
}

//获取最后结算时间
export function getLastSettleDate(data){
  return request({
    url: '/MA_settlement/getLastSettleDate',
    method: 'get',
    params: data
  })
}


//支付
export function settlePay(data){
  return request({
    url: '/MA_settlement/pay',
    method: 'get',
    params: data
  })
}


