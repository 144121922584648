<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="商户名称">
          <el-select v-model="formInline.shopId" style="width:400px; " filterable clearable placeholder="请选择商户">
            <el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
				</el-form-item>
        <el-form-item label="结算日期">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="addRow">新增</el-button>
          <el-button type="primary" @click="exportAll">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="商户名称"
        prop="shopName">
      </el-table-column>
      <el-table-column
        width="280"
        label="时间段">
        <template slot-scope="scope">
          {{ scope.row.startDate?scope.row.startDate.substr(0,10):'商户接入' }} ~ {{ scope.row.endDate?scope.row.endDate.substr(0,10):'' }}
        </template>
      </el-table-column>
      <el-table-column
        label="结算金额"
        prop="settleAmount">
      </el-table-column>
      <el-table-column
        label="结算时间"
        width="160"
        prop="settleTime">
      </el-table-column>
      <el-table-column
        label="支付金额"
        prop="payAmount">
      </el-table-column>
      <el-table-column
        label="支付状态"
        prop="payState">
      </el-table-column>
      <el-table-column
        label="支付时间"
        width="160"
        prop="payTime">
      </el-table-column>
      <el-table-column
        label="操作"
        width="260">
        <template slot-scope="scope">
          <el-button @click="detailRow(scope.row)" type="primary" size="mini">详情</el-button>
          <el-button v-if="scope.row.payState === '未支付'" @click="payRow(scope.row)" type="primary" size="mini">支付</el-button>
          <el-button @click="exportRow(scope.row)" type="danger" size="mini">导出</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>
    <el-dialog class="dialogForm" title="新增商户结算" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px" style="width: 94%;">
        <el-form-item label="商户" prop="shopId">
          <el-select
            filterable
            v-model="form.shopId"
            @change="getLastSettleDateFn(form.shopId)"
            collapse-tags
            placeholder="请选择商户">
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算截至时间" prop="expireTime">
          <el-date-picker v-model="form.expireTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束时间" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="openMerchantDialog('form')">订单列表</el-button>
        <el-button :disabled="loading" type="primary" @click="submitForm('form')"> {{ loading?'结算中':'提交结算'}}</el-button>
        <el-button @click="resetForm('form')">重 置</el-button>
      </div>
    </el-dialog>

    <el-dialog title="支付" :visible.sync="payActionDialog" :close-on-click-modal="false" width="500px">
      <el-form :model="payform"  :rules="rules2"  ref="payform">
        <el-form-item label="支付金额" label-width="120px" prop="payAmount">
          <el-input v-model="payform.payAmount"></el-input>
        </el-form-item>
        <el-form-item label="支付时间" label-width="120px" prop="paydate">
          <el-date-picker
            v-model="payform.paydate"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择选择支付时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="gotoPay('payform')">确 定</el-button>
        <el-button @click="resetPayForm('payform')">重 置</el-button>
      </div>
    </el-dialog>


    <!-- 商户结算详情 -->
    <el-dialog title="商户结算详情" :visible.sync="shopCountDetailTableVisible" :close-on-click-modal="false">
      <el-table :data="shopCountTable">
        <el-table-column property="orderNo" label="订单编号"  width="180"></el-table-column>
        <el-table-column property="goodsName" label="商品名称"></el-table-column>
        <el-table-column property="fee" label="金额"></el-table-column>
        <el-table-column property="price" label="单价"></el-table-column>
        <el-table-column property="number" label="数量"></el-table-column>
        <el-table-column property="orderTime" label="下单时间"></el-table-column>
        <el-table-column property="userName" label="用户名"></el-table-column>
        <el-table-column property="confirmTime" label="收货时间"></el-table-column>
      </el-table>

      <div class="pagebox" v-if="countTotalRow > countPageSize">
        <el-pagination
          @size-change="countHandleSizeChange"
          @current-change="countHandleCurrentChange"
          :current-page="countCurrentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="countPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="countTotalRow">
        </el-pagination>
      </div>

    </el-dialog>

    <!-- 订单列表 -->
    <el-dialog title="订单列表" :visible.sync="merchantDialog" :close-on-click-modal="false" width="70%">
      <div class="search">
        <el-form :inline="true" :model="searchForm" v-if="form.shopId == ' '">
          <el-form-item label="商户名称">
            <el-select v-model="searchForm.shopId" style="width:400px; " filterable clearable placeholder="请选择商户">
              <el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchMerchant">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="orderList">
        <el-table-column property="shop_name" label="商户名称"></el-table-column>
        <el-table-column property="order_no" label="订单编号"  width="180"></el-table-column>
        <el-table-column property="goods_name" label="商品名称"></el-table-column>
        <el-table-column property="fee" label="金额"></el-table-column>
        <el-table-column property="price" label="单价"></el-table-column>
        <el-table-column property="number" label="数量"></el-table-column>
        <el-table-column property="order_time" label="下单时间"></el-table-column>
        <el-table-column property="user_name" label="用户名"></el-table-column>
        <el-table-column property="confirm_time" label="收货时间"></el-table-column>
      </el-table>
      <div class="pagebox">
        <el-pagination
          @size-change="orderHandleSizeChange"
          @current-change="orderHandleCurrentChange"
          :current-page="orderCurrentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="orderPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="orderTotalRow">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { settlementList, addSettlement, settlementDetail, exportSettlement, exportSettlementAll, getLastSettleDate, settlePay, unSettleOrderList } from '@/api/settlement'
import { getShopsPage } from '@/api/merchantManage'
import { sureMessageBox }　from '@/utils/common'
export default {
  name: "noticemanage",
  data () {
    return {
      shopList: [],
      formInline: {
        shopId: null,
        time: '',
      },
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      tableData: [],
      dialogFormVisible: false,
      lastSettleDate:'',
      form: {
        shopId: '',
        expireTime: ''
      },
      payform:{
        paydate: '',
        payAmount: ''
      },
      payActionDialog: false,
      payRowId: '',
      rules: {
        shopId: [
          { required: true, message: '请选择商户', trigger: 'blur' }
        ],
        expireTime: [
          { required: true, message: '请选择结算截至时间', trigger: 'blur' }
        ]
      },
      rules2: {
        paydate: [
          { required: true, message: '请选择支付日期', trigger: 'blur' }
        ],
        payAmount: [
          { required: true, message: '请输入支付金额', trigger: 'blur' }
        ]
      },
      //设置结算日期可选区间
      pickerOptions: {
        disabledDate: time => {
          if(this.lastSettleDate){
            return time.getTime() > Date.now() - 8.64e7 || time.getTime() < new Date(this.lastSettleDate).getTime()  - 8.64e7;
          }else{
            return time.getTime() > Date.now() - 8.64e7;  //可选历史天、不可选当前天、不可选未来天
          }
        }
      },
      shopCountDetailTableVisible: false,
      shopCountTable: [],
      countCurrentPage: 1,
      countTotalRow: 0,
      countPageSize: 10,
      orderCurrentPage: 1,
      orderTotalRow: 0,
      orderPageSize: 10,
      shopCountId: '',
      loading: false,
      merchantDialog: false,
      searchForm: {
        shopId: '',
        settleDate: '',
      },
      orderList: [],
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getShoper();
    this.getList();
  },

  methods: {
    openMerchantDialog(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.searchForm.shopId = that.form.shopId;
          that.searchForm.expireTime = that.form.expireTime;
          that.merchantDialog = true;
          that.getUnSettleOrderList();
        }
    })
    },
    searchMerchant() {
      this.orderCurrentPage = 1;
      this.getUnSettleOrderList();
    },
    getUnSettleOrderList() {
      unSettleOrderList({
        shopId: this.searchForm.shopId,
        settleDate: this.searchForm.expireTime,
        pageSize: this.orderPageSize,
        pageNum: this.orderCurrentPage,
      }).then(res => {
        if(res.result == 200) {
          this.orderList = res.detail.list;
          this.orderTotalRow = res.detail.totalRow;
        }
      })
    },
    //商户列表翻页
    orderHandleSizeChange(val) {
      this.orderPageSize = val;
      this.orderCurrentPage = 1;
      this.getUnSettleOrderList();
    },
    //商户列表详情翻页
    orderHandleCurrentChange(val) {
      this.orderCurrentPage = val;
      this.getUnSettleOrderList();
    },
    getShoper() {
      getShopsPage({
        pageSize: '100000',
        pageNum: '1'
      }).then(res=>{
				if(res.result==200){
					this.shopList = res.detail.list;
          this.shopList.unshift({
            name: '全部',
            id: ' '
          })
				}else{
					this.$message.error(res.description)
				}
			})
    },
    getList() {
      settlementList({
        shopId: this.formInline.shopId,
        startDate: this.formInline.time? this.formInline.time[0] : '',
        endDate: this.formInline.time? this.formInline.time[1] : '',
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }).then( res => {
        if( res.result == 200) {
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }
      })
    },
    //获取最后一次结算时间
    getLastSettleDateFn(shopId){
      this.searchForm.shopId = shopId
      getLastSettleDate({
        shopId
      }).then( res => {
        if(res.result == 200) {
          this.lastSettleDate = res.detail;
        } else {
          this.$message.warning(res.description)
        }
      })
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //添加结算
    addRow() {
      this.dialogFormVisible = true;
      this.resetForm('form');
    },
    //查看商户结算详情
    detailRow(row){
      if(row){
        this.shopCountId = row.id;
      }
      settlementDetail({
          settleId: this.shopCountId,
          pageSize: this.countPageSize,
          pageNum: this.countCurrentPage
        }).then( res => {
          if(res.result == 200) {
            // console.log(res)
            this.shopCountDetailTableVisible = true;
            this.shopCountTable = res.detail.list;
            this.countTotalRow = res.detail.totalRow;
          } else {
            this.$message.warning(res.description)
          }
        })
    },
    //商户结算详情翻页
    countHandleSizeChange(val) {
      this.countPageSize = val;
      this.countCurrentPage = 1;
      this.detailRow();
    },
    //商户结算详情翻页
    countHandleCurrentChange(val) {
      this.countCurrentPage = val;
      this.detailRow();
    },
    //确认支付
    gotoPay(formName){
      console.log(this.payform.paydate)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          settlePay({
            settleId: this.payRowId,
            payTime: this.payform.paydate,
            payAmount: this.payform.payAmount
          }).then(res=>{
            if(res.result == 200) {
              this.$message.success('操作成功');
              this.currentPage = 1;
              this.getList();
              this.payActionDialog = false;
              this.resetPayForm('payform');
            } else {
              this.$message.warning(res.description)
            }
          })
        }
      })
    },
    //添加结算
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          addSettlement({
            shopId: this.form.shopId,
            settleDate: this.form.expireTime
          }).then( res => {
            this.loading = false;
            if(res.result == 200) {
              this.$message.success('结算成功');
              this.currentPage = 1;
              this.getList();
              this.dialogFormVisible = false;
            } else {
              this.$message.warning(res.description)
            }
          })
        }
      })
    },
    //支付
    payRow(row){
      this.payActionDialog = true;
      this.payRowId = row.id;
      this.payform.payAmount = row.settleAmount;
      
    },
    //导出结算
    exportRow(row){
      sureMessageBox('是否确认导出该条商户结算信息').then(res=>{
        exportSettlement({
          settleId: row.id
        }).then(res=>{
          if(res.result == 201){ //.result == 200
            this.$message.warning(res.description)
          }else{
            const data = res;
            const a = document.createElement('a');
            const blob = new Blob([data],{type:"application/octet-stream"});
            const blobUrl = window.URL.createObjectURL(blob);
            this.download(blobUrl) ;
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //导出所有
    exportAll(){
      sureMessageBox('是否确认导出商户结算信息').then(res=>{
        exportSettlementAll({
          shopId: this.formInline.shopId,
          startDate: this.formInline.time? this.formInline.time[0] : '',
          endDate: this.formInline.time? this.formInline.time[1] : '',
        }).then(res=>{
          if(res.result == 201){ //.result == 200
            this.$message.warning(res.description)
          }else{
            const data = res;
            const a = document.createElement('a');
            const blob = new Blob([data],{type:"application/octet-stream"});
            const blobUrl = window.URL.createObjectURL(blob);
            this.download(blobUrl) ;
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    download(blobUrl) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.download = '结算信息.xlsx'; //文件名
      a.href = blobUrl;
      a.click();
    },
    //重置支付表单
    resetPayForm(formName){
      this.payform = {
        paydate: '',
        payAmount: ''
      }
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
    //重置新增结算表单
    resetForm(formName) {
      this.form = {
        shopId: '',
        expireTime: ''
      }
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.loading = false;
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 20px 10px;
}
.pagebox{
  margin: 20px 0;
  text-align: right;
}
.dialog-footer{
  text-align: center;
}
</style>